<template>
  <div class="layout">
    <div class="view-width-100">
      <div class="view-height-85">
        <img
          v-if="!res.list[0].extraParams || !res.list[0].extraParams.isVideo"
          alt
          class="image-mode"
          :src="res.list[0].img"
        />
        <video
          v-else
          controls
          :src="res.list[0].extraParams.videoSrc"
          style="width: 100%; height: 100%"
        ></video>
      </div>
      <div class="view-height-85">
        <img
          v-if="!res.list[1].extraParams || !res.list[1].extraParams.isVideo"
          alt
          class="image-mode"
          :src="res.list[1].img"
        />
        <video
          v-else
          controls
          :src="res.list[1].extraParams.videoSrc"
          style="width: 100%; height: 100%"
        ></video>
      </div>
    </div>
    <div class="view-width-100">
      <img
        v-if="!res.list[2].extraParams || !res.list[2].extraParams.isVideo"
        alt
        class="image-mode"
        :src="res.list[2].img"
      />
      <video
        v-else
        controls
        :src="res.list[2].extraParams.videoSrc"
        style="width: 100%; height: 100%"
      ></video>
    </div>
  </div>
</template>

<script>
  export default {
    title: '上二下一',
    props: ['res'],
  }
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    background-size: cover;
  }
  img {
    width: 100% !important;
    height: 85px !important;
  }
  .view-width-100 {
    display: flex;
    width: 100%;
    height: 85px;
    padding: 1px 0;
  }
  .view-height-85 {
    width: 50%;
    padding: 0 1px;
  }
</style>
